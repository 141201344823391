import React, { useState } from 'react';
import { URL } from '../../action/types';
import { connect } from 'react-redux';
import { ToggleContact } from '../../action/toggleContact'
import './contact.css';

const Contact = props =>  {
    const [collection, setCollection] = useState({});
    const [buffer, setBuffer] = useState(false);
    const [success, setSuccess] = useState(false);
    const [validationMessage, setValidationMessage] = useState("");

    function closeForm() {
        props.ToggleContact(false);
        setSuccess(false)
        setValidationMessage("")
        setCollection({})
    }

    function handleSubmit(event) {
        event.preventDefault();
        if (validation()) {
            setBuffer(true)
            
            fetch(`${URL}/api/contact`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify(collection)
            })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    setSuccess(true)
                } else {
                    setValidationMessage(data.message)
                }
                setBuffer(false)
            })
            .catch(error => {
                console.log(error)
                setValidationMessage(error)
                setBuffer(false)
    
            })
        }
        
    }

    function handleChange(event) {
        setValidationMessage("")
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.id;
        
        setCollection({...collection,
            [name]: value
        
        })
        

    }

    function validation() {
        var message = "Fields required:"
        let isValid = true;

        var contactInputs = document.getElementsByClassName("contact-form-input")
        for (var i=0; i< contactInputs.length; i++) { 
            if (contactInputs[i].required && (collection[contactInputs[i].id] === "" || collection[contactInputs[i].id] === undefined)) {
                var space = ", "
                if (message === "Fields required:") {
                    space = " "
                }
                message = message + space + contactInputs[i].name
                isValid = false;
            } 

            

        }
        
        message = message + "."
        setValidationMessage(message)

        if (isValid) {
            setValidationMessage("")
            return true;
        }


        return false;

    }

    return  (props.contact.isOpen ?
        
    <div  id="contact-form-container" className="contact-form-container"> 
        <div className="contact-form-shade" onClick={closeForm}/>

        <div id="contact-form" className="contact-form">
            <p className="contact-form-header"> PARTNER WITH US</p>
            {!success ?
            <form className="contact-form1">
                <p className="contact-form-inputbox">
                    <label className="contact-form-labels"> Company:
                    <span className="contact-form-labels-required"></span></label > 

                    <input 
                    className="contact-form-input"
                    id="company"
                    name="Company"
                    required={false}
                    onChange={handleChange}
                    type="text" 
                    /> 
                </p>
                
                <p className="contact-form-inputbox">
                    <label  className="contact-form-labels"> First Name:
                    <span className="contact-form-labels-required">* </span></label > 

                    <input 
                    className="contact-form-input"
                    id="firstName"
                    name="First Name"
                    required={true}
                    onChange={handleChange}
                    type="text" 
                    /> 
                </p>

                <p className="contact-form-inputbox">
                    <label  className="contact-form-labels"> Last Name:
                    <span className="contact-form-labels-required">* </span></label > 

                    <input 
                    className="contact-form-input"
                    id="lastName"
                    name="Last Name"
                    required={true}
                    onChange={handleChange}
                    type="text" 
                    /> 
                </p>

                <p className="contact-form-inputbox">
                    <label  className="contact-form-labels"> Job-title:
                    <span className="contact-form-labels-required"></span></label > 

                    <input 
                    className="contact-form-input"
                    id="title"
                    name="Job title"
                    required={false}
                    onChange={handleChange}
                    type="text" 
                    /> 
                </p>

                <p className="contact-form-inputbox">
                    <label  className="contact-form-labels"> E-mail:
                    <span className="contact-form-labels-required">* </span></label > 

                    <input 
                    className="contact-form-input"
                    id="email"
                    name="E-mail"
                    required={true}
                    onChange={handleChange}
                    type="text" 
                    /> 
                </p>


                <p className="contact-form-inputbox">
                <label  className="contact-form-labels"> Description:
                <span className="contact-form-labels-required">* </span></label > 
                
                <textarea 
                 className="contact-form-input"
                 id="description"
                 name="Description"
                 required={true}
                 type="textarea"
                 onChange={handleChange}
                 rows={5}
                 /> </p>
                
            </form> : null}

            {success ? <div className="contact-form-success-message">
                <p> <span> Thank you for submitting your message! We will contact you soon.</span></p>
            </div> : null }

            <div className="contact-form-buttons"> 
                <button type="submit" onClick={closeForm} className="contact-form-button-close">{success ?"Done" : "Close"}</button>
                {!success ?<button 
                type="submit" 
                onClick={handleSubmit} 
                className="contact-form-button-save">{buffer ? "Loading..." : "Submit"}</button> : null }
            </div>

            {!success ? <div className="contact-form-validation">
                <p> {validationMessage}</p>
            </div> : null }

          </div> 

    </div> : null
    
    )

}

const mapStateToProps = state => ({
    contact: state.contact
})

const mapDispatchToProps = dispatch => {
    return {
        ToggleContact: (newState) => 
        dispatch(ToggleContact(newState))
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps )(Contact);
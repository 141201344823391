import React, { useState } from 'react';
import { URL } from '../../action/types';

import './settings.css';

const SettingsSaveButton = props =>  {
    const [buffer, setBuffer] = useState(false);

    function saveRequest(token, type) {
        const updateURL = `${URL}/api/crud/${props.collectionName}/${props.collection._id}`
        const createURL = `${URL}/api/crud/${props.collectionName}`


        setBuffer(true)

        if (type === "PUT") {
            props.collection["modifiedByID"] = "5f94830c9b401315d0467966";
            delete props.collection.createdByID;
            delete props.collection.createdDate;
            delete props.collection.modifiedDate;
        }

        if (type === "POST") {
            props.collection["modifiedByID"] = "5f94830c9b401315d0467966";
            props.collection["createdByID"] = "5f94830c9b401315d0467966";
            Array.prototype.forEach.call(props.inputs, function(input) {
                props.collection[input.name] = input.value
                 
            });
        }



        fetch(type === "PUT" ? updateURL : createURL, {
        method: type,
        headers: {
            'auth-token': token,
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        body: JSON.stringify(props.collection)
        })
        .then(response => response.json())
        .then(data => {
            props.updateRow(data.collection, type)
            props.closeForm()
            setBuffer(false)

        })
        .catch(error => {
            console.log(error)
            setBuffer(false)

        })


    }

        // Validate fields and display validation error message
    function formValidation() {
        var message = "Fields required:"
        let isValid = true;
        
        props.fields.forEach((header, index) => {
            if (header.collectionID !== "60271cd00930704a009269f0" ) {
                if (header.required && (props.collection[header.apiName] === "" || props.collection[header.apiName] === undefined)) {
                    var space = ", "
                    if (message === "Fields required:") {
                        space = " "
                    }
                    message = message + space + header.displayName
                    isValid = false;
                } 

            }

        })
        
        message = message + "."
        props.displayValidation(message)

        if (isValid) {
            props.displayValidation('')
            return true;
        }


        return false;

        
    }

    function handleClick() {

        if (formValidation()) {
            let store = JSON.parse(localStorage.getItem('fabylab-login'))
            let token = null
            if ((store && store.login)) {
              token = store.token
          
            }
    
            if (props.isNewForm) {
                saveRequest(token, 'POST')
            } else {
                saveRequest(token, 'PUT')
            }

            props.displayValidation('')


        }

        

        




    }

  return  (

    <button 
    type="submit" 
    onClick={handleClick} 
    className="form-settings-button-save">{buffer ? "Loading..." : "Save"}</button>
      
    ) 
}

export default SettingsSaveButton;
import React, { useState } from 'react';
import { Form, Container, Row } from 'react-bootstrap';
import './login.css';
import { useHistory } from "react-router-dom";
import ResetPasswordButton from './resetpasswordbutton'
import { connect } from 'react-redux';
import { Auth } from '../../action/authactions'

const ForgotPassword = (props) => {
    const history = useHistory();
    const [email, setEmail] = useState('');
    
    function resetForm() {
        setEmail('')
        history.push(`/login/signin`);
    }
    
    function handleChange(event) {
      if (event.target.name === 'email') {
        setEmail(event.target.value)
      }
    
    }

    return  (
        <div className="login">
          <Container fluid="md"> 
            <Row>
            
              <Form>
              <Form.Group controlId="formBasicCheckbox">
                  <Form.Text className="text-muted" color="red">
                  <div className="form-title">
                    Reset Password
                  </div>
                  <span>A message with the instructions on how to reset your password
                    will be sent to your e-mail address.</span> 
                  </Form.Text>
                  <Form.Text className="text-muted" color="red">
                    
                  </Form.Text>
              </Form.Group>
              <Form.Group controlId="formBasicEmail">
                  <Form.Label>E-mail address</Form.Label>
                  <Form.Control
                  name='email' 
                  type="email" 
                  placeholder="Enter E-mail" 
                  value={email}
                  onChange={handleChange}/>
              </Form.Group>

              <Form.Group controlId="button">
                  <ResetPasswordButton 
                    name="Reset"
                    email={email}
                    click={resetForm}
                    />
              </Form.Group>
              
              </Form>
              </Row>
          </Container>

        </div>
    
    )

}

const mapStateToProps = state => ({
  credentials: state.credentials,
  server: state.server
})

export default connect(mapStateToProps, {Auth} )(ForgotPassword);
import React from 'react';
import Projects from '../Projects/projects';
import {marketingImage} from './marketingsImage';
import './marketingSales.css';


const MarketingSales = props => {

    return (
      <div className="marketingSales-container">
      <div className="marketingSales-banner-container">
        <div className="marketingSales-banner-text">
          <h1><span className="marketingSales-header" style={{color: "black"}}>Marketing<span style={{color: "orange"}}> & Sales</span></span></h1>
          <span className="marketingSales-text" style={{color: "gray"}}>Transform your enterprise by leveraging automation in your workforce.</span>
        </div>
        <div className="marketingSales-banner-image">{marketingImage} </div>
        
      
      </div>
        <Projects
        defaultFilter={["60163393e75d838e0248655a"]}/>
      </div>
    )

};

export default MarketingSales;
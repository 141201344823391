import React, { useState } from 'react';
import { Form, Container, Row } from 'react-bootstrap';
import './login.css';
import { useHistory } from "react-router-dom";
import NewPasswordButton from './newpasswordbutton'
import { connect } from 'react-redux';
import { Auth } from '../../action/authactions'

const NewPassword = (props) => {

    const history = useHistory();
    const [password, setPassword] = useState('');
    const [verifyPassword, setVerifyPassword] = useState('');
    
    function resetForm() {
      setPassword('')
      setVerifyPassword('')
      history.push(`/login/signin`);
    }
    
    function handleChange(event) {
      if (event.target.name === 'password') {
        setPassword(event.target.value)
      }

      if (event.target.name === 'verify-password') {
        setVerifyPassword(event.target.value)
      }
    
    }

    return  ( 

      <div className="login-container">
          
        <div className="login-form-container">

        <Container fluid="md"> 
            <Row>
            
              <Form>

              <Form.Group controlId="formBasicCheckbox">
                  <Form.Text className="text-muted" color="red">
                  <div className="form-title">
                    New Password
                  </div>
                  <span>Your protection is our top priority. Please make sure you change your
                      password every 3 months.
                    </span>
                  </Form.Text>
              </Form.Group>

              <Form.Group controlId="formBasicPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control 
                  name="password"
                  type="password" 
                  placeholder="Password" 
                  value={password}
                  onChange={handleChange}/>
              </Form.Group>

              <Form.Group controlId="formBasicPassword">
                  <Form.Label>Verify Password</Form.Label>
                  <Form.Control 
                  name="verify-password"
                  type="password" 
                  placeholder="Verify Password" 
                  value={verifyPassword}
                  onChange={handleChange}/>
              </Form.Group>

              <Form.Group controlId="button">
                  <NewPasswordButton 
                    name="Submit"
                    password={password}
                    verifyPassword={verifyPassword}
                    token={props.match.params.token}
                    click={resetForm}
                    />
              </Form.Group>
              
              </Form>
              </Row>
          </Container>
          
          
        </div>
      <div className="login-background-container"></div>

    </div> 


    
    )

}

const mapStateToProps = state => ({
  credentials: state.credentials,
  server: state.server
})

export default connect(mapStateToProps, {Auth} )(NewPassword);
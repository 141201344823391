import { SERVER, AUTH, URL } from './types';

export const UpdateUser = (company, title, firstName, lastName, phone) => dispatch => {
    dispatch({
      type: SERVER,
      payload: {isLoading: true, error: false, success: false, message: ''}
    })

    let store = JSON.parse(localStorage.getItem('fabylab-login'))
    let token = null
    if ((store && store.login)) {
      token = store.token
  
    }

    fetch(`${URL}/api/login/update`, {
        method: 'POST',
        headers: {
          'auth-token': token,
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify({
          company: company,
          title: title,
          firstName: firstName,
          lastName: lastName,
          phone: phone
        })
      }).then(response => response.json()
        ).then(data => {

          dispatch({
            type: SERVER,
            payload: {isLoading: false, error: data.error, success: data.success, message: data.message}
          })

          if (data.success) {
            dispatch({
              type: AUTH,
              payload: {
                auth: true,
                user: data.user
              }
                
            })

          }



        }
        ).catch(error => {
          console.log('error', error)

          dispatch({
            type: SERVER,
            payload: {isLoading: false, error: true, message: "Server connection error"}
        })

      })
}

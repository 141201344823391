import { ADMIN } from '../action/types';

const initialState = {
    users: [],
};

const adminReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADMIN:
            return action.payload
        default:
            return state
    }
}

export default adminReducer;
import { ISTRACKER, URL } from '../../action/types';

export const tracker = async (path) => {

    function uniqueID() {
        let a = new Uint32Array(3);
        window.crypto.getRandomValues(a);
        return (performance.now().toString(36)+Array.from(a).map(A => A.toString(36)).join("")).replace(/\./g,"");
    };

    var visitorID = localStorage.getItem('visitorID')
    var sessionID = sessionStorage.getItem('sessionID')
    var lastPage = sessionStorage.getItem('lastPage')

    if (visitorID === null) {
        visitorID = uniqueID()
        localStorage.setItem('visitorID', visitorID);
        
    } 

    if (sessionID === null) {
        sessionID = uniqueID()
        sessionStorage.setItem('sessionID', sessionID);
    }

    if (ISTRACKER && !path.startsWith("/settings")) {
        var visitorInfo = {}

        await fetch(`https://extreme-ip-lookup.com/json/`, {
            method: 'GET'
        }).then(response => response.json()
        ).then(data => {
            // Stores the fetched data in a state variable
            
            if (data.status === "success") {
                visitorInfo = data;
                visitorInfo["ipAddress"] = data.query
            }
    
        })
        .catch(error => {
            console.log(error)
        })

        visitorInfo["url"] = path
        visitorInfo["visitorID"] = visitorID
        visitorInfo["sessionID"] = sessionID
        visitorInfo["lastPage"] = lastPage
        visitorInfo["referrer"] = document.referrer

        fetch(`${URL}/api/visitors`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        body: JSON.stringify(visitorInfo)
        })
        .then(response => response.json())
        .then(data => {

        })
        .catch(error => {
            console.log(error)

        })

        sessionStorage.setItem('lastPage', path);



    }

    
};



import React, { useLayoutEffect, useState } from 'react';
import { URL } from '../../action/types';
import { dateTime } from '../../Utilities/converters';
import BarChart from '../Dashboard/barChart'
import PieChart from '../Dashboard/pieChart'
import TableChart from '../Dashboard/tableChart'
import './dashboard.css';

const Dashboard = props =>  {
  const [dashboard, setDashboard] = useState([]);
  const [updated, setUpdated] = useState("");
  const [success, setSuccess] = useState(false);
  const [buffer, setBuffer] = useState(true);
  
  useLayoutEffect(() => {
    setSuccess(false)
    setBuffer(true)

    let store = JSON.parse(localStorage.getItem('fabylab-login'))
    let token = null
    if ((store && store.login)) {
      token = store.token
  
    }
    
    fetch(`${URL}/api/dashboard`, {
        method: 'GET',
        headers: {
        'auth-token': token,
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        }
    })
    .then(response => response.json())
    .then(data => {
        setDashboard(data.dashboard.data)
        setUpdated(dateTime(data.dashboard.updated))
        setSuccess(data.success)
        setBuffer(false)
    })
    .catch(error => {
        console.log(error)
        setBuffer(false)
        setSuccess(false)
    })

  }, [])

  return  (

      <div className="dashboard-container">
          <span className="dashboard-update">Dashboard updated at: {updated}</span>
          <div className="dashboard-components">
              {dashboard.map((chart, index) =>
                chart.type === "pieChart" ?
                <PieChart
                key={chart.id}
                id={chart.id}
                title={chart.title}
                labels={chart.labels}
                data={chart.data}
                /> :

                chart.type === "vertChart" ?
                <BarChart
                key={chart.id}
                id={chart.id}
                title={chart.title}
                isVertical={true}
                labels={chart.labels}
                data={chart.data}
                xType="category"/> :

                chart.type === "horChart" ?
                <BarChart
                key={chart.id}
                id={chart.id}
                title={chart.title}
                isVertical={false}
                labels={chart.labels}
                data={chart.data}
                xType="linear"/>:

                chart.type === "table" ?
                <TableChart
                key={chart.id}
                id={chart.id}
                title={chart.title}
                labels={chart.labels}
                data={chart.data}
                /> :
                
                null
              )}

          </div>

      </div>
      
    ) 
}

export default Dashboard;
import React, { useState } from 'react';
import './loader.css';

const CardsSkeleton = () => {

    const [cardGhosts, setGhosts] = useState([1,2,3,4,5,6,7,8,9,10,11,12]);

    return  (
        <div className="skeleton-container"> 
            {/* <div className="skeleton-filter-container"> 
                <div className="skeleton-filter-line1"/> 
            </div> */}
            <div className="card-skeleton-container">
                
                {cardGhosts.map((project, index) =>
                <div key={index} className="ghost-container">
                    <div className="ghost-image"/>
                    <div className="ghost-line1"/>
                    <div className="ghost-line2"/>

                </div>)}
            </div>
        </div>
    
    )

}

export default CardsSkeleton;
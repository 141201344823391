import React from 'react';
import { Button } from 'react-bootstrap';
import './login.css';
import { connect } from 'react-redux';
import { UpdateUser } from '../../action/updateaction'
import { UpdateEmail } from '../../action/updateemailaction'


const UpdateButton = props =>  {

  function onSubmit(event) {
    props.click(event);

    if (props.emailDisabled) {
      props.UpdateUser(props.company, props.title, props.firstName, props.lastName, props.phone);

    } else {
      props.UpdateEmail(props.newEmail);

      setTimeout(() => {
        if (props.server.success) {
         props.clickEdit()
        }
      }, 3000);

    }
    
  }

  return  (
      
      <div className="update-button">
              <style type="text/css">
                {`
                .btn-primary {
                  background-color: orange;
                  border: orange;
                  color: white;
                }

                .btn-primary:hover {
                  background-color: gray;
                  border: orange;
                  color: white;
                }
                `}
              </style>
              <Button
              name="submit"
              variant="primary"
              disabled={props.server.isLoading}
              onClick={!props.server.isLoading ? onSubmit : null}
              
              >
              {props.server.isLoading ? 'Loading…' : props.name }
              </Button>

      </div>
  
  )

}

const mapStateToProps = state => ({
    server: state.server
})

const mapDispatchToProps = dispatch => {
    return {
      UpdateUser: (company, title, firstName, lastName, phone) => 
      dispatch(UpdateUser(company, title, firstName, lastName, phone)),
      UpdateEmail: (newEmail) => 
      dispatch(UpdateEmail(newEmail)),
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(UpdateButton);

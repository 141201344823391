import { SERVER, URL } from './types';

export const Forgot = (email) => dispatch => {
    dispatch({
      type: SERVER,
      payload: {isLoading: true, error: false, success: false, message: ''}
    })

    fetch(`${URL}/api/login/forgot-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify({
          email: email,
        })
      }).then(response => response.json()
        ).then(data => {

          dispatch({
            type: SERVER,
            payload: {isLoading: false, error: data.error, success: data.success, message: data.message}
          })

        }
        ).catch(error => {
          console.log('error', error)

          dispatch({
            type: SERVER,
            payload: {isLoading: false, error: true, message: "Server connection error"}
        })

      })
}

import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { Alert } from 'react-bootstrap';
import './alert.css';

const UserAlert = props =>  {
    const [visible, setVisible] = useState(false);
    const [alertType, setAlertType] = useState('');

    useEffect(() => {

        if (props.server.error) {
            setVisible(true);
            setAlertType('danger')
        }

        if (props.server.success) {
            setVisible(true);
            setAlertType('success')
        }


        const timer = setTimeout(() => {
            setVisible(false);
        }, 5000);
        return () => clearTimeout(timer);
        }, [props.server.error, props.server.success]);

  return  ( visible ? 
        
        <div className="user-alert">
        <Alert variant={alertType}>
            {props.server.message}
        </Alert>
        </div> : null
      
      
    ) 
}

const mapStateToProps = state => ({
  server: state.server,
  credentials: state.credentials
})

export default connect(mapStateToProps)(UserAlert);
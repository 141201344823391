import React from "react";
import './login.css';
import avatar from './avatar-picture.png';


const UploadImage = props =>  {

  const [file, setFile] = React.useState("");
  const [image, setImage] = React.useState("");


  function handleUpload(event) {
    setFile(event.target.files[0]);
    let uploadimage = URL.createObjectURL(event.target.files[0]);
    setImage(uploadimage);

  }

  return (
    <label htmlFor="upload-button">
    <div className="profile-image-wraper">
      { !image ? 
      
      <div className="profile-image">
          
          <img className="profile-image" src={avatar} alt="upload"/>

      </div> :

      <div className="profile-image">
      <img className="profile-image" src={image} alt="upload"/>
      </div> }


      { !file ? 
      
      <div className="profile-image-text">
          UPLOAD
      </div> :

      <div className="profile-image-text">
      EDIT
      </div> }

      <input
            type="file"
            id="upload-button"
            style={{ display: "none" }}
            onChange={handleUpload}
          />
      

    </div>
    </label>

    

  );
}

export default UploadImage;
import { SERVER } from '../action/types';

const initialState = {
    isLoading: false,
    error: false,
    success: false,
    message: ''
  };

const serverReducer = (state = initialState, action) => {
    switch (action.type) {
        case SERVER:
            return action.payload
        default:
            return state
    }
}

export default serverReducer;
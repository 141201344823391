import React from 'react';
import Projects from '../Projects/projects';
import './automation.css';

const Automation = props => {

    return (
        <div className="automation-container">
          <div className="automation-banner-container">
            <div className="automation-banner-text">
              <h1><span style={{color: "black"}}>Automation <span style={{color: "orange"}}>Solutions</span></span></h1>
              <span style={{color: "gray", fontSize: "1.5rem"}}>Transform your enterprise by leveraging automation in your workforce.</span>
            </div>
          
          </div>
        <Projects
        defaultFilter={["60163383e75d838e02486558"]}/>
      </div>
    )

};

export default Automation;
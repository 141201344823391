import React, { useState, useLayoutEffect } from 'react';
import SaveButton from './settingsSaveButton';
import Loader from '../Loader/loader';
import { URL } from '../../action/types';
import { dateTime } from '../../Utilities/converters';

import './settings.css';

const SettingsForm = props =>  {
    const [collection, setCollection] = useState({});
    const [buffer, setBuffer] = useState(false);
    const [validationMessage, setValidationMessage] = useState("");

    useLayoutEffect(() => {

        // Scrool form up
        if (!props.hidden && !buffer) {
            document.getElementById('form-settings').scrollTo(0,0);
        }

        // Clear form
        if (props.isNewForm) {
            clearForm()
        }

         // Fetch item form data
        if(!props.hidden && !props.isNewForm) {
            let store = JSON.parse(localStorage.getItem('fabylab-login'))
            let token = null
            if ((store && store.login)) {
              token = store.token
          
            }


            setValidationMessage("")
            setBuffer(true)
            fetch(`${URL}/api/crud/${props.collectionName}/${props.formId}`, {
                method: 'GET',
                headers: {
                'auth-token': token,
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                }
            })
            .then(response => response.json())
            .then(data => {
                // Stores the fetched data in a state variable
                setCollection(data.collection[0])
                setBuffer(false)

            })
            .catch(error => {
                console.log(error)
                setBuffer(false)

            })
        }

    }, [props.hidden, props.collectionName, props.formId, props.isNewForm])

    // Handles the input changes from the form and changes the JSON state variable
    function handleChange(event) {
        setValidationMessage("")
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        
        setCollection({...collection,  
            [name]: value
        
        })
        

    }

    function clearForm() {
        setCollection({})
        var dropdowns = document.getElementsByTagName("select")

        for (var i=0; i< dropdowns.length; i++) { 
            dropdowns[i].selectedIndex = 0
        }

        setValidationMessage("")
    }

    // Changes the date format from BSON to YYYY-MM-DD 
    function htmlDate(longDate) {

        var date = new Date(longDate)
        var month = (date.getMonth() + 1) < 10 ? 
        "0" + (date.getMonth() + 1): (date.getMonth() + 1)

        var day = date.getDate() < 10 ? 
        "0" + (date.getDate() ): (date.getDate() )

        return date.getFullYear() + 
        "-" +  month +
        "-" +  day;

    }

    function displayValidation(message) {
        setValidationMessage(message)
    }


  return  (props.success ? buffer ? <Loader/> :

      <div  id="form-settings-container" className="form-settings-container">
            
            <div hidden={props.hidden} onClick={props.closeForm} className="form-settings-shade">

            </div>  
          
          <div id="form-settings" hidden={props.hidden} className="form-settings">
                <p className="form-settings-header"> {props.collectionName.charAt(0).toUpperCase() + props.collectionName.slice(1)} Form</p>
            <form className="form-settings1">
                {props.fields.map((header, index) =>
                header.collectionID !== "60271cd00930704a009269f0"  ?
                <p className="form-settings-inputbox" key={header.apiName}>
                <label  className="form-settings-labels" key={header.apiName}>{header.displayName} 
                <span className="form-settings-labels-required">{header.required ? "*" : null} </span></label > 
                
                {header.fieldType === "textarea" || header.fieldType === "html"?
                <textarea 
                 className="form-settings-input"
                 name={header.apiName} 
                 type={header.fieldType} 
                 value={collection[header.apiName] === undefined ? "" : collection[header.apiName]} 
                 onChange={handleChange}
                 rows={5}
                 /> :

                 header.fieldType === "lookup" ?
                    <select className="form-settings-input" value={collection[header.apiName]} name={header.apiName} onChange={handleChange}>
                        <option disabled defaultValue> -- select an option -- </option>
                        {props.lookups[header.lookup].map((value,key) => 
                        <option value={value._id} key={key}>{value.name}</option>)}
                    </select>
                 
                 :

                 header.fieldType === "boolean" ? 
                    <input 
                    name={header.apiName} 
                    type='checkbox'
                    onChange={handleChange} 
                    checked={collection[header.apiName] === undefined ? "" : collection[header.apiName]} 
                    />

                 :

                 <input 
                 className="form-settings-input"
                 onChange={handleChange}
                 name={header.apiName} 
                 type={header.fieldType} 
                 value={collection[header.apiName] === undefined ? "" : 
                        header.fieldType === "date" ? htmlDate(collection[header.apiName]) :
                        collection[header.apiName]
                } 
                 />}


                 </p>
                : null)}
                
            </form>

            <div className="form-settings-buttons"> 
                <button type="submit" onClick={props.closeForm} className="form-settings-button-close">Close</button>
                <SaveButton
                isNewForm={props.isNewForm}
                collectionName={props.collectionName}
                collection={collection}
                fields={props.fields}
                inputs={document.getElementsByClassName("form-settings-input")}
                closeForm={props.closeForm}
                updateRow={props.updateRow}
                displayValidation={displayValidation}
                />
            </div>

            <div className="form-settings-validation">
                <p> {validationMessage}</p>
            </div>
            
            {!props.isNewForm ?
            <div className="form-settings-system">
            <b>Created by: </b>{collection.createdByID } <b>Created date: </b>{dateTime(collection.createdDate)}
            <b>Modified by: </b>{collection.modifiedByID } <b>Modified date: </b>{dateTime(collection.modifiedDate)}
            </div> : null}

          </div>
          
      </div> : null
      
    ) 
}

export default SettingsForm;
import { dateTime, shortDate, lookupName } from './converters';

export const exportExcel = (fileName, headers, content, lookupFields) => { 
    var finalContent = downloadFromJson(headers, content, lookupFields)
    download(fileName, finalContent);

}

function downloadFromJson(headers, content, lookupFields) {
    var finalContent = ''
    headers.forEach((column, index) => { 
        finalContent = finalContent + column.displayName + "\t"
    })

    finalContent = finalContent + "\n"

    content.forEach((row, index) => { 
        headers.forEach((column, index) => { 
            if (row[column.apiName] === undefined || row[column.apiName] === null || column.fieldType === "html") {
                finalContent = finalContent + "\t"
            } else {
                var cell = column.fieldType === "lookup" ? lookupName(lookupFields, column.lookup, row[column.apiName]) :
                column.fieldType === "date" ? shortDate(row[column.apiName]) :
                column.fieldType === "dateTime" ? dateTime(row[column.apiName]) : 
                row[column.apiName];
                finalContent = finalContent + cell + "\t"
            }
            
        })
        finalContent = finalContent + "\n"
    })


    return finalContent;
}


function download(filename, content) {
    var downloadLink = document.createElement('a');
    downloadLink.setAttribute('href', 'data:application/vnd.ms-excel;charset=utf-8,' + encodeURIComponent(content));
    downloadLink.setAttribute('download', filename + `.xls`);
  
    downloadLink.style.display = 'none';
    document.body.appendChild(downloadLink);
  
    downloadLink.click();
  
    document.body.removeChild(downloadLink);
  }
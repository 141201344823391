import React from 'react';
import { Button} from 'react-bootstrap';
import './login.css';
import {connect } from 'react-redux';
import { Forgot } from '../../action/forgotpasswordaction'


const ResetPasswordButton = props =>  {

    function onSubmit(event) {
        event.preventDefault();
        props.Forgot(props.email);
        props.click()

    
    }

    return  (
        
        <div className="reset-password-button">
                <style type="text/css">
                  {`
                  .btn-primary {
                    background-color: orange;
                    border: orange;
                    color: white;
                  }

                  .btn-primary:hover {
                    background-color: gray;
                    border: orange;
                    color: white;
                  }
                  `}
                </style>
                <Button
                name="submit"
                variant="primary"
                disabled={props.server.isLoading}
                onClick={!props.server.isLoading ? onSubmit : null}
                
                >
                {props.server.isLoading ? 'Loading…' : props.name }
                </Button>

        </div>
    
    )

}

const mapStateToProps = state => ({
    server: state.server
})

const mapDispatchToProps = dispatch => {
    return {
      Forgot: (email) => 
      dispatch(Forgot(email))
    }
  }
  
export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordButton);

import { AUTH, SERVER, URL } from './types';

export const Auth = () => dispatch => {
    dispatch({
        type: SERVER,
        payload: {isLoading: true, error: false, message: ''}
    })

    let store = JSON.parse(localStorage.getItem('fabylab-login'))
    let token = null
    if ((store && store.login)) {
      token = store.token
  
    }

    fetch(`${URL}/api/login/authenticate`, {
        method: 'GET',
        headers: {
        'auth-token': token,
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        }
    })

    .then(response => response.json())
    .then(data => {

        dispatch({
            type: AUTH,
            payload: data
        })

        dispatch({
            type: SERVER,
            payload: {isLoading: false, error: false, success: false, message: data.message}
        })

    })
    .catch(error => {
        console.log(error)
        dispatch({
            type: SERVER,
            payload: {isLoading: false, error: true, message: "Server connection error"}
        })

      
    })

}
import React from 'react';
import { Button } from 'react-bootstrap';
import './login.css';
import {connect } from 'react-redux';
import { SignIn } from '../../action/signinaction'


const SignInButton = props =>  {

    function onSubmit(event) {
        event.preventDefault();
        props.SignIn(props.email, props.password);

        if (props.credentials.auth) {
            props.click()
        }
    
    }

    return  (
        

        <div className="signin-button">
                <style type="text/css">
                  {`
                  .btn-primary {
                    background-color: orange;
                    border: orange;
                    color: white;
                  }

                  .btn-primary:hover {
                    background-color: gray;
                    border: orange;
                    color: white;
                  }
                  `}
                </style>
                <Button
                name="submit"
                type="submit"
                variant="primary"
                disabled={props.server.isLoading}
                onClick={!props.server.isLoading ? onSubmit : null}
                
                >
                {props.server.isLoading ? 'Loading…' : props.name }
                </Button>

        </div>
    
    )

}

const mapStateToProps = state => ({
    server: state.server,
    credentials: state.credentials
})

const mapDispatchToProps = dispatch => {
    return {
        SignIn: (email, password) => dispatch(SignIn(email, password))
    }
  }
  
export default connect(mapStateToProps, mapDispatchToProps)(SignInButton);

import React, { useState } from 'react';
import Logo from '../Logo/logo';
import { Link } from 'react-router-dom';
import { ToggleContact } from '../../action/toggleContact'
import { connect } from 'react-redux';
import { SignOut } from '../../action/signoutaction'
import { Auth } from '../../action/authactions'
import {ToolbarContent} from '../../Content/content';
import NavDropdown from 'react-bootstrap/NavDropdown';
import './sidedrawer.css';

const SideDrawer = props => {
    const [isHovered1, setIsHovered1] = useState(false);
    const [isClicked1, setIsClicked1] = useState(false);
    const [isHovered2, setIsHovered2] = useState(false);
    const [isClicked2, setIsClicked2] = useState(false);

    function onSubmit(event) {
        event.preventDefault();
        props.click()
        props.SignOut();
      
    }

    function protectedClick() {
        props.Auth();
      
    }

    function openContact() {
        props.click()
        props.ToggleContact(true)
    }

    let drawwerClasses = 'side-drawer';
    if (props.show) {
        drawwerClasses = 'side-drawer open';
    }
    return (
        <nav className={drawwerClasses}>
            <div className="sidedrawer-logo"><Link onClick={props.click} to="/"><Logo/></Link></div>

            <ul >
                {ToolbarContent.map((item, index) => 
                    item.type === "multiple" ? 
                    <li key={index}>
                        <NavDropdown 
                        show={isClicked1 || isHovered1}
                        onMouseEnter={() => setIsHovered1(true)}
                        onMouseLeave={() => setIsHovered1(false)}
                        onToggle={() => setIsClicked1(!isClicked1)}
                        title={item.name} id="basic-nav-dropdown">
                            <div className="dropdown-items">
                                {item.children.map((item, index) => 
                                <div key={index} onClick={props.click}><Link to={item.url}>{item.name}</Link></div>
                                
                                )}
                            </div> 
                        </NavDropdown>
                    </li>
                    : 
                    <li key={index} onClick={props.click}><Link to={item.url}>{item.name}</Link></li>

                )}

                <li className="drawer-contact" onClick={openContact}><div className="drawer-contacts"><Link to="#">Contact</Link></div></li>
                {props.credentials.auth ? 
                    <li>
                        <NavDropdown 
                         show={isClicked2 || isHovered2}
                         onMouseEnter={() => setIsHovered2(true)}
                         onMouseLeave={() => setIsHovered2(false)}
                         onToggle={() => setIsClicked2(!isClicked2)}
                         title={props.credentials.user.lastName + ", " + props.credentials.user.firstName}  
                         id="basic-nav-dropdown">
                            <div onClick={protectedClick} className="dropdown-items">
                                <div onClick={props.click}><Link to="/profile">Profile</Link></div>
                                <div onClick={props.click}><Link to="/settings">Settings</Link></div>
                                
                            </div>
                            <NavDropdown.Divider />

                            <div onClick={protectedClick} className="dropdown-items">
                                <div onClick={onSubmit}><Link to="#">Log Out</Link></div>
                            </div>
                        </NavDropdown>
                    </li>    
                : <li onClick={props.click}><Link to="/login/signin">Sign In</Link></li>}
            </ul>

            
        </nav>

        );
};
const mapStateToProps = state => ({
    credentials: state.credentials
})

const mapDispatchToProps = dispatch => {
    return {
        SignOut: () => dispatch(SignOut()),
        Auth: () => dispatch(Auth()),
        ToggleContact: (newState) => 
        dispatch(ToggleContact(newState))
    }
  }
  
export default connect(mapStateToProps, mapDispatchToProps)(SideDrawer);
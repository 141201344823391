import React, {useEffect, useState} from 'react';
import { Redirect , Route } from 'react-router-dom';
import Loader from '../Loader/loader'

const ProtectedRoute = (
    {
        isLoading,
        auth,
        path, 
        component: Component, 
        ...rest}) =>  {

    const [buffer, setBuffer] = useState(true);

    
    useEffect(() => {
        const timer = setTimeout(() => {
            setBuffer(isLoading)
        }, 1000);
        return () => clearTimeout(timer);
        }, [buffer, isLoading]);

  return  (
      
      <Route 
        {...rest} 
        render={(props) => {
            if (buffer) { 
                return <Loader {...props} /> 
            } else {

                if (auth) {
                    return <Component {...props} /> 
                }
                else if (!buffer) {
                    return <Redirect
                    to={{
                      pathname: "/login/signin",
                      source: path,
                    }}
                  />
                }
            }

        }}

      />
    ) 
}


  
export default ProtectedRoute;

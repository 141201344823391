import React, { useState, useLayoutEffect } from 'react';
import { useHistory } from "react-router-dom";
import { URL } from '../../action/types';
import Skeleton from '../Loader/cardSkeleton';
import Filter from '../CategoriesFilter/categoriesFilter';
import InfiniteScroll from '../Tables/infiniteScroll';
import ArticleCard from './articleCard';
import './articles.css';

const ArticlesMain = props => {

  const [filterArray, setFilterArray] = useState(["60163279e75d838e02486557",
  "60163383e75d838e02486558", 
  "6016338ee75d838e02486559", 
  "60163393e75d838e0248655a"])
  const [buffer, setBuffer] = useState(true);
  const [articles, setArticles] = useState([]);
  const [topArticleID, setTopArticleID] = useState();
  const [topArticleImage, setTopArticleImage] = useState();
  const [topArticleName, setTopArticleName] = useState();
  const [topArticleURL, setTopArticleURL] = useState();
  const [topArticleDescription, setTopArticleDescription] = useState();
  const [categories, setCategories] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [nextPage, setNextPage] = useState(0);
  const [numPages, setNumPages] = useState(0);
  const [success, setSuccess] = useState(false);
  const history = useHistory();

  useLayoutEffect(() => {

    fetch(`${URL}/api/articles/all?currentPage=0`, {
      method: 'GET',
      headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      }
    })
    .then(response => response.json())
    .then(data => {
      setArticles(data.collection)
      setTopArticleID(data.collection[0]._id)
      setTopArticleImage(data.collection[0].image)
      setTopArticleName(data.collection[0].name)
      setTopArticleDescription(data.collection[0].description)
      setTopArticleURL(data.collection[0].url)
      setCategories(data.lookups.categories)
      setNumPages(data.numPages)
      setBuffer(false)

      
      setSuccess(data.success)
    })
    .catch(error => {
      console.log(error)
      setBuffer(false)
      setSuccess(false)
    })
    
  }, [])


  useLayoutEffect(() => {

    if (nextPage - currentPage === 1 ) {
      setBuffer(true)
      fetch(`${URL}/api/articles/all?currentPage=${nextPage}`, {
        method: 'GET',
        headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        }
    })
    .then(response => response.json())
    .then(data => {

        if (data.success) {
          data.collection.map(article => {
            return setArticles(prevArticles => [...prevArticles, article]);
          })
          setCategories(data.lookups.categories)
          setNumPages(data.numPages)
          setCurrentPage(data.currentPage)
        }
        setNextPage(currentPage)
        setBuffer(false)
    })
    .catch(error => {
        console.log(error)
        setSuccess(false)
        setNextPage(currentPage)
    })

    }

    
  }, [nextPage, currentPage])

  function articleLink(path) {
    history.push(path);
  }
  
  function changeFilter(newFilter) {
    setFilterArray(newFilter)
  }

  function refreshPage() {
    if (currentPage + 1 <= numPages && !buffer) {
      setNextPage(currentPage + 1)
    }
    
  }

    return (
      <div className="articles-container">

        {success ?<div className="articles-top-container" onClick={() => articleLink(`articles/${topArticleURL}`)}>
          <div className={`articles-text-header`}>
            <span>{topArticleName}</span>
          </div>
          <div className="articles-top-text-container"> 
            <div className={`articles-text-description`}>
                <span>{topArticleDescription}</span>
            </div>
            <div className="articles-top-image" style={{backgroundImage: `url(${topArticleImage})`}}> 
              <span className="articles-top-image-new">NEW ARTICLE</span>
            </div>
          </div>
        </div> : null}
        
        {success ? <div className="articles-list-container">
          <Filter
          categories={categories}
          changeFilter={changeFilter}
          list={articles}
          filter={filterArray}
          />
        
        
          <div className="articles-cards-container">
            {articles.map((article, index) =>
                filterArray.includes(article.categoryID) && article._id !== topArticleID ?
                <ArticleCard
                key={index}
                article={article}
                click={articleLink}
                categories={categories}
                /> : null
              )}
          </div>
        </div> : null}

        {buffer ? <Skeleton/> : null}
        <InfiniteScroll
        refreshPage={refreshPage}
        />
      </div>
    )

};

export default ArticlesMain;
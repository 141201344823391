import React, { useState, useLayoutEffect } from 'react';
import { URL } from '../../action/types';
import Filter from '../CategoriesFilter/categoriesFilter';
import InfiniteScroll from '../Tables/infiniteScroll';
import ProjectCard  from './projectCard';
import Skeleton from '../Loader/cardSkeleton';
import './projects.css';

const Projects = props => {
  const [filterArray, setFilterArray] = useState(props.defaultFilter)
  const [buffer, setBuffer] = useState(true);
  const [projects, setProjects] = useState([]);
  const [categories, setCategories] = useState([]);
  const [status, setStatus] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [nextPage, setNextPage] = useState(0);
  const [numPages, setNumPages] = useState(0);
  const [success, setSuccess] = useState(false);

  useLayoutEffect(() => {

    fetch(`${URL}/api/projects?currentPage=0`, {
      method: 'GET',
      headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      }
    })
    .then(response => response.json())
    .then(data => {
      setProjects(data.collection)
      setCategories(data.lookups.categories)
      setStatus(data.lookups.status)
      setNumPages(data.numPages)
      setBuffer(false)

      
      setSuccess(data.success)
    })
    .catch(error => {
      console.log(error)
      setBuffer(false)
      setSuccess(false)
    })
    
  }, [])


  useLayoutEffect(() => {

    if (nextPage - currentPage === 1 ) {
      setBuffer(true)
      fetch(`${URL}/api/projects?currentPage=${nextPage}`, {
        method: 'GET',
        headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        }
    })
    .then(response => response.json())
    .then(data => {

        if (data.success) {
          data.collection.map(project => {
            return setProjects(prevProjects => [...prevProjects, project]);
          })
          setCategories(data.lookups.categories)
          setStatus(data.lookups.status)
          setNumPages(data.numPages)
          setCurrentPage(data.currentPage)
        }
        setNextPage(currentPage)
        setBuffer(false)
    })
    .catch(error => {
        console.log(error)
        setSuccess(false)
        setNextPage(currentPage)
    })

    }

    
  }, [nextPage, currentPage])

  
  function changeFilter(newFilter) {
    setFilterArray(newFilter)
  }

  function refreshPage() {
    if (currentPage + 1 <= numPages && !buffer) {
      setNextPage(currentPage + 1)
    }
    
  }

    return (
    <div className="projects-container"> 
      
      <div>
        {success ? <Filter
        categories={categories}
        changeFilter={changeFilter}
        list={projects}
        filter={filterArray}
        /> : null}
      </div>

     
      <div className="projects-cards-container">

        {success ? projects.map((project, index) =>
          filterArray.includes(project.categoryID) ?

          <ProjectCard 
          key={index}
          id={`projectCards${index + 1}`}
          name={project.name}
          statuses={status}
          status={project.statusID}
          startDate={project.startDate}
          endDate={project.endDate}
          categories={categories}
          category={project.categoryID}
          skills={project.skills}
          application={project.application}
          description={project.description}
          image={project.image}
          /> : null
        ): null}
      </div>

      {buffer ? <Skeleton/> : null}
      <InfiniteScroll
      refreshPage={refreshPage}
      />


    </div>
    )

};

export default Projects;




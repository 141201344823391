import { CONTACT } from '../action/types';

const initialState = {
    isOpen: false
  };

const contactReducer = (state = initialState, action) => {
    switch (action.type) {
        case CONTACT:
            return action.payload
        default:
            return state
    }
}

export default contactReducer;
import React from 'react';
import './projectCard.css';


const ProjectCard = props => {

    const imageStyle = {
        backgroundImage: `url(${props.image})`,
    };

    function displayName(list, id) {
        var display = ""
        list.map((item, index) => 
            item._id === id ? display = item.name : null    
        )
        return display;
    }

    function shortDate(longDate) {
        var date = new Date(longDate)

        return (date.getMonth() + 1) + 
        "/" +  date.getDate() +
        "/" +  date.getFullYear();
    }

    return (
        <div id={props.id} className="projectCard">
            <div id={`${props.id}-image`} className={`projectCard-image`} style={imageStyle}/>
            <div className={`projectCard-shade`} />

            <div className={`projectCard-text-container`}>
                <span className={`projectCard-text-header`}>
                    {props.name}
                </span>

                <div className={`projectCard-text-content`}>
                    <span className={`projectCard-label`}>Status:</span>
                    <span className={`projectCard-status-${props.status}`}>{displayName(props.statuses, props.status)}</span>
                    <span className={`projectCard-status-dot-${props.status}`}></span> <br/>
                    <span className={`projectCard-label`}>Start Date:</span>
                    <span className={`projectCard-text`}>{shortDate(props.startDate)}</span>
                    <span className={`projectCard-label`}>End Date:</span>
                    <span className={`projectCard-text`}>{shortDate(props.endDate)}</span> <br/>
                    <span className={`projectCard-label`}>Category:</span>
                    <span className={`projectCard-text`}>{displayName(props.categories, props.category)}</span> <br/>
                    <span className={`projectCard-label`}>Skills:</span>
                    <span className={`projectCard-text`}>{props.skills}</span> <br/>
                    <span className={`projectCard-label`}>Application:</span>
                    <a href={props.application} className={`projectCard-text`} target="_blank"  rel="noreferrer">{props.application}</a> <br/>
                    <span className={`projectCard-label`}>Description:</span>
                    <span className={`projectCard-text`}>{props.description}</span> <br/>
                </div>

            </div>

        </div>
    )

};

export default ProjectCard;

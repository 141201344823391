import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faChartPie, faCode, faFunnelDollar, faNewspaper, faTable, faHeading, faEye } from '@fortawesome/free-solid-svg-icons'


    export const ToolbarContent = [
        {"type": "single", "name": "Home", "url": "/"},
        {"type": "single", "name": "About", "url": "/about"},
        {"type": "multiple", "name": "Projects", "children": [
            {"type": "single", "name": "Artificial Intelligence", "url": "/ai"},
            {"type": "single", "name": "Automation", "url": "/automation"},
            {"type": "single", "name": "Digital Strategy", "url": "/digital"},
            {"type": "single", "name": "Marketing & Sales", "url": "/marketing"}]},
        {"type": "single", "name": "Articles", "url": "/articles"}
        
    ]

    const profileIcon = <FontAwesomeIcon icon={faUser} />
    const dashboardIcon = <FontAwesomeIcon icon={faChartPie} />
    const visitorsIcon = <FontAwesomeIcon icon={faEye} />
    const tablesIcon = <FontAwesomeIcon icon={faTable} />
    const fieldsIcon = <FontAwesomeIcon icon={faHeading} />
    const leadsIcon = <FontAwesomeIcon icon={faFunnelDollar} />
    const postsIcon = <FontAwesomeIcon icon={faNewspaper} />
    const projectsIcon = <FontAwesomeIcon icon={faCode} />


    export const SettingsOptions = [
        {name: "Dashboard", url: "/settings/dashboard", icon: dashboardIcon},
        {name: "Visitors", url: "/settings/visitors", icon: visitorsIcon},
        {name: "Tables", url: "/settings/collections", icon: tablesIcon},
        {name: "Headers", url: "/settings/schemas", icon: fieldsIcon},
        {name: "Users", url: "/settings/users", icon: profileIcon},
        {name: "Projects", url: "/settings/projects", icon: projectsIcon},
        {name: "Articles", url: "/settings/articles", icon: postsIcon},
        {name: "Leads", url: "/settings/leads", icon: leadsIcon},
        {name: "Jobs", url: "/settings/jobs", icon: leadsIcon}
    ];
import React, { useState } from 'react';
import { Form, Button, Container, Row } from 'react-bootstrap';
import './login.css';
import { Link, useLocation } from "react-router-dom";
import SignInButton from './signinbutton'
import { connect } from 'react-redux';
import { Auth } from '../../action/authactions'

const SignIn = (props) => {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    
    function resetForm() {
        setEmail('')
        setPassword('')
    }
    
    function handleChange(event) {
      if (event.target.name === 'email') {
        setEmail(event.target.value)
      }
    
      if (event.target.name === 'password') {
        setPassword(event.target.value)
      }
    
    }

    return  (

            <Container fluid="md"> 
              <Row>
              
                <Form>
                <Form.Group controlId="formBasicCheckbox">
                    <Form.Text className="text-muted" color="red">
                    <div className="form-title">
                      Welcome
                    </div>
                    <span>Your protection is our top priority. Please make sure you change your
                      password every 3 months.
                    </span>
                    </Form.Text>
                </Form.Group>
                <Form.Group controlId="formBasicEmail">
                    <Form.Label>E-mail address</Form.Label>
                    <Form.Control
                    name='email' 
                    type="email" 
                    placeholder="Enter E-mail" 
                    value={email}
                    onChange={handleChange}/>
                </Form.Group>

                <Form.Group controlId="formBasicPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control 
                    name="password"
                    type="password" 
                    placeholder="Password" 
                    value={password}
                    onChange={handleChange}/>
                </Form.Group>
                <Form.Group controlId="button">
                    <SignInButton 
                      name="Sign In"
                      email={email}
                      password={password}
                      click={resetForm}
                      />
                </Form.Group>

                <Form.Group controlId="button">

                <Button variant="link" size="sm">
                  <Link
                    to={{
                      pathname: "/login/forgotpassword",
                    }}
                  >Forgot Password?</Link> </Button>

                </Form.Group>
                
                </Form>
                </Row>
            </Container>
    
    )

}

const mapStateToProps = state => ({
  credentials: state.credentials,
  server: state.server
})

export default connect(mapStateToProps, {Auth} )(SignIn);
import React, { useState, useEffect } from 'react';
import SettingsMenu from './settingsMenu';
import SettingsTable from './settingsTable';
import Dashboard from './dashboard';

import './settings.css';

const Settings = props =>  {

    const [collection, setCollection] = useState(props.match.params.url);

    useEffect(() => {
      setCollection(props.match.params.url)
    }, [props.match.params.url]);


  return  (

    <div className="settings-container-menu">
        <div className="settings-menu">
            <SettingsMenu />
        </div>

        {collection === undefined ? 
          <div className="settings-container-main">
              This is main
          </div>  
        : null}

        <div className="settings-container-table">
          {collection !== "dashboard" ?
          <SettingsTable
          collectionName={collection}
          /> 
          : <Dashboard/>}
        </div>
    </div>




      



      
    ) 
}

export default Settings;
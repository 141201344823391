import React, { useEffect } from 'react';
import { Switch, Route, useHistory  } from 'react-router-dom';
import LandingPage from '../LandingPage/landingpage';
import About from '../About/about';
import AI from '../AI/ai';
import Automation from '../Automation/automation';
import DigitalStrategy from '../DigitalStrategy/digitalStrategy';
import MarketingSales from '../MarketingSales/marketingSales';
import Profile from '../Login/profile';
import Settings from '../Settings/settings';
import ArticlesMain from '../Articles/articlesMain';
import Article from '../Articles/article';
import Login from '../Login/login';
import NewPassword from '../Login/newpassword';
import VerifyEmail from '../Login/verifyemail';
import Logo from '../Logo/logo';
import NotFound from '../NotFound/notFound';
import { tracker } from '../Tracker/tracker';
import ProtectedRoute from './protected.route';
import {connect } from 'react-redux';
import { Auth } from '../../action/authactions'

const Main = (props) => {  

  const history = useHistory() 

  useEffect(() => {
     return history.listen((location) => { 
        tracker(location.pathname);
     }) 
  },[history]) 

  return (
  <Switch>
    <Route exact path="/" component={LandingPage} />
    <Route exact path="/about" component={About} />
    <Route exact path="/ai" component={AI} />
    <Route exact path="/automation" component={Automation} />
    <Route exact path="/digital" component={DigitalStrategy} />
    <Route exact path="/marketing" component={MarketingSales} />
    <Route path="/logo" component={Logo} />
    <Route path="/login/:url" component={Login} />
    <Route path="/new-password/:token" component={NewPassword} />
    <Route path="/verify-email/:token" component={VerifyEmail} />
    <ProtectedRoute isLoading={props.server.isLoading} auth={props.credentials.auth} path="/profile" component={Profile} />
    <ProtectedRoute isLoading={props.server.isLoading} auth={props.credentials.auth} path="/settings/:url" component={Settings} />
    <ProtectedRoute isLoading={props.server.isLoading} auth={props.credentials.auth} path="/settings" component={Settings} />
    <Route path="/articles/:id" component={Article} />
    <Route path="/articles" component={ArticlesMain} />
    <Route path="*" component={NotFound} />
  </Switch>
  )
}

const mapStateToProps = state => ({
  credentials: state.credentials,
  server: state.server
})

export default connect(mapStateToProps, {Auth} )(Main);

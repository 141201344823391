import React from 'react';
import {Container, Spinner} from 'react-bootstrap';

const Loader = () => {

    return  (
        
        <div className="loader">
            <Container fluid="md"> 
              <div className="spinner-wrapper">
              <Spinner className="profile-spinner" animation="border" />       
              </div>
            </Container>
        </div>
    
    )

}

export default Loader;
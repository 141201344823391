import { AUTH, SERVER } from './types';

export const SignOut = () => dispatch => {
    dispatch({
      type: SERVER,
      payload: {isLoading: true, error: false, sucsess: false, message: ''}
    })

    dispatch({
      type: AUTH,
      payload: {auth: false, message: ''}
    })

    localStorage.setItem('fabylab-login', JSON.stringify({
      login: false,
      token: '',
    }))

    dispatch({
      type: SERVER,
      payload: {isLoading: false, error: false, success: true, message: 'Logged Out'}
    })

}

import React, { useState, useLayoutEffect } from 'react';
import { URL } from '../../action/types';
import { shortDate } from '../../Utilities/converters';
import Loader from '../Loader/loader';
import NotFound from '../NotFound/notFound';
import './articles.css';

const Article = props => {

    const [collection, setCollection] = useState({});
    const [buffer, setBuffer] = useState(false);
    const [success, setSuccess] = useState(false);

    useLayoutEffect(() => {

        if (props.match.params.id !== "all") {
            setBuffer(true)
            fetch(`${URL}/api/articles/${props.match.params.id}`, {
                method: 'GET',
                headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                }
            })
            .then(response => response.json())
            .then(data => {
                // Stores the fetched data in a state variable
                setCollection(data.collection[0])
                setSuccess(data.success)
                setBuffer(false)
    
            })
            .catch(error => {
                console.log(error)
                setBuffer(false)
                setSuccess(false)
    
            })

        }


            

    }, [])

    const imageStyle = {
        backgroundImage: `url(${collection.image})`,
    };

    return (buffer ? <Loader/> :  success ?
        <div className="article-container">
            
            <div className="article-container-content">
                    <div className="article-container-title">
                        <span>{collection.name}</span> 
                    </div>

                    <div className="article-container-image" style={imageStyle} />


                    <div className="article-container-author">
                        <div className="article-container-author-image"/>
                        <div className="article-container-author-child">
                            <div>
                            <span className="article-container-author-label">Author: </span> 
                            <span className="article-container-author-value">Fabio Chen</span> 
                            </div>
                            <div>
                            <span className="article-container-author-label">Published:</span> 
                            <span className="article-container-author-value">{shortDate(collection.createdDate)}</span>
                            </div>
                        </div>
                    </div>

                    <div className="article-container-article">
                        <div dangerouslySetInnerHTML={ {__html: collection.article} } />
                    </div>
            </div>
             
            
        </div> : <NotFound/>
        
    )

};

export default Article;
import React from 'react';
import './notFound.css';

const NotFound = props => {

    return (
        <div className="notFound-container"/>
    )

};

export default NotFound;
import React, { useLayoutEffect, useState } from 'react';
import './pagination.css';

const Pagination = props =>  {
    const [currentPage, setCurrentPage] = useState(0);
    const [previousPage, setPreviousPage] = useState(0);
    const [numPages, setNumPages] = useState(props.numPages);
    const [pageArray, setPageArray] = useState([]);

    const disableStyle = "color:lightgray; cursor:text;";
    const enableStyle = "color:blue; cursor:pointer;";

    useLayoutEffect(() => {

        var array = [];
        for (var i = 0; i < numPages; i++) {
            array.push(i+1)
            setPageArray(pageArray => [...pageArray, i]);
        }
        setPageArray(array);

        var back = document.getElementById("table-pagination-back")
        var next = document.getElementById("table-pagination-next")
        var current = document.getElementById(currentPage)
        var previous = document.getElementById(previousPage)

        if (current) {
            current.style = disableStyle
        }

        if ((previousPage !== currentPage) && previous) {
            previous.style = enableStyle
        }

        if (currentPage === 0) {
            back.style = disableStyle
            
        } else {
            back.style = enableStyle
        }

        if (currentPage === numPages - 1) {
            next.style = disableStyle
        } else {
            next.style = enableStyle
        }


     }, [currentPage, previousPage, numPages]);

    function handleClick(event) {
        event.preventDefault();
        event.stopPropagation()

        var pageNow = currentPage;

        if (event.target.className === 'table-pagination-back') {
            if (currentPage > 0) {
                pageNow--
            }
        }

        if (event.target.className === 'table-pagination-next') {
            if (currentPage < numPages - 1) {
                pageNow++
            }
        }

        if (event.target.className === 'table-pagination-page') {
            pageNow = parseInt(event.target.id)
        }

        setPreviousPage(currentPage)
        setCurrentPage(pageNow)
        if (pageNow !== currentPage) {
            props.refreshPage(pageNow)
        }
        
    } 

  return  (

    <div className="table-pagination-container">
        <div id="table-pagination-back" value="2" className="table-pagination-back" onClick={handleClick}>
            {`<< Back`}
        </div>

        <div className="table-pagination-page-container">
            {pageArray.map((item, index) => 
                <div key={index} id={index} style={index === 0 ? {color:"lightgray", cursor:"text"} : null} onClick={handleClick} className="table-pagination-page">
                    {index + 1}
                </div>

            )}

        </div>

        <div id="table-pagination-next" className="table-pagination-next" onClick={handleClick}>
            {`Next >>`} 
        </div>

    </div>

    ) 
}

  
export default Pagination;
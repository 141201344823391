import React, { useState } from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap'
import { useLocation, Link } from 'react-router-dom'
import { SettingsOptions } from '../../Content/content';

import './settings.css';

const SettingsMenu = props =>  {
    const location = useLocation();
    const [options] = useState(SettingsOptions);


  return  (

    <div className="settings-menu-small">
        <Breadcrumb>
            {options.map((item, index) =>
                <LinkContainer key={index} to={item.url}>
                <Breadcrumb.Item active={location.pathname === item.url}><span>{item.icon} </span>{item.name}</Breadcrumb.Item>
                </LinkContainer>
            )}
        </Breadcrumb>
    </div>

    ) 
}

export default SettingsMenu;
import React from 'react';
import './App.css';
import Main from './components/Routes/main';
import Toolbar from './components/Toolbar/toolbar';
import SideDrawer from './components/SideDrawer/sidedrawer';
import Backdrop from './components/BackDrop/backdrop';
import Footer from './components/Footer/footer';
import Contact from './components/Contact/contact';
import UserAlert from './components/Alert/alert';
import { tracker } from './components/Tracker/tracker';
import 'bootstrap/dist/css/bootstrap.min.css';
import {connect } from 'react-redux';
import { Auth } from './action/authactions'


class App extends React.Component {
  state = {
    sideDrawerOpen: false,
    message: '',
  };

  componentDidMount = () => {
    this.props.Auth()
    tracker(window.location.pathname)

  }

  drawerToggleClickHandler = () => {
    this.setState((prevState) => {
      return {sideDrawerOpen: !prevState.sideDrawerOpen}

    });

  };
  
  backdropClickHandler = () => {
    this.setState({sideDrawerOpen: false});
  };

  render() {
    let backDrop;

    if (this.state.sideDrawerOpen) {
      backDrop = <Backdrop click={this.backdropClickHandler}/>;
    }
    return (
        <div className="page-container">

        {/* Test resolution */}
        {/* <div className="display-resolution">
          <div className="display-resolution-phone">PHONE (0PX-640PX)</div>
          <div className="display-resolution-tablet">TABLET (641PX-768PX)</div>
          <div className="display-resolution-laptop">LAPTOP (769PX-1024PX)</div>
          <div className="display-resolution-large">DESKTOP (greater 1024PX)</div>
        </div> */}
        
          <div className="page-header">
            <Toolbar drawerClickHandler={this.drawerToggleClickHandler}/>
          </div>

          <SideDrawer show={this.state.sideDrawerOpen} click={this.backdropClickHandler}/>
          {backDrop}



          <div className="page-content">

              <UserAlert/>
              <Contact/>
              <Main/>
        
          </div>
                  


          <div className="page-footer"><Footer /></div>
            

        </div>
      
      
    );
  }
}

const mapStateToProps = state => ({
  credentials: state.credentials
})

export default connect(mapStateToProps, {Auth} )(App);
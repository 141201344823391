import { AUTH } from '../action/types';

const initialState = {
    auth: false,
    message: '',
    user: {}
  };

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case AUTH:
            return action.payload
        default:
            return state
    }
}

export default authReducer;
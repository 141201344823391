import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ToggleContact } from '../../action/toggleContact'
import { connect } from 'react-redux';
import { SignOut } from '../../action/signoutaction'
import { Auth } from '../../action/authactions'
import { NavDropdown } from 'react-bootstrap';
import DrawerToggle from '../SideDrawer/drawertoggle';
import Logo from '../Logo/logo';
import {ToolbarContent} from '../../Content/content';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faCog, faSignOutAlt } from '@fortawesome/free-solid-svg-icons'

import './toolbar.css';

const profile = <FontAwesomeIcon icon={faUser} />
const settings = <FontAwesomeIcon icon={faCog} />
const signout = <FontAwesomeIcon icon={faSignOutAlt} />

const Toolbar = props => {
    const [isHovered1, setIsHovered1] = useState(false);
    const [isClicked1, setIsClicked1] = useState(false);
    const [isHovered2, setIsHovered2] = useState(false);
    const [isClicked2, setIsClicked2] = useState(false);

    function onSubmit(event) {
        event.preventDefault();
        props.SignOut();
      
    }

    function protectedClick() {
        props.Auth();
      
    }

    function openContact() {
        props.ToggleContact(true)
    }

    return (
        <div className="toolbar-container">
            <div className="toolbar-items">

                <div className="toolbar-logo-image">
                <Link to="/"><Logo /></Link>
                </div>
         
                <div className="toolbar-logo-text-item"><Link to="/">FABY</Link></div>
                <div className="toolbar-logo-lab"><Link to="/">LAB</Link></div>

                <div className="toggle-button">
                    <DrawerToggle click={props.drawerClickHandler} />
                </div>

                {ToolbarContent.map((item, index) => 
                item.type === "multiple" ? 
                <div key={index} className="toolbar-item">
                    <NavDropdown 
                        key={index}
                        show={isClicked1 || isHovered1}
                        onMouseEnter={() => setIsHovered1(true)}
                        onMouseLeave={() => setIsHovered1(false)}
                        onToggle={() => setIsClicked1(!isClicked1)}
                        title={item.name} 
                        id="basic-nav-dropdown">
                        <div className="dropdown-items">
                        {item.children.map((item, index) => 
                        <div key={index}><Link to={item.url}>{item.name}</Link></div>
                        
                        )}
                        </div>
                    </NavDropdown>
                </div>
                
                :
                
                <div key={index} className="toolbar-item">
                   <Link to={item.url}>{item.name}</Link>
                </div>
                
                )}

                <div key={"contact"} 
                className="toolbar-item"
                onClick={openContact}
                >
                    <Link to="#">Contact</Link>
                </div>

                {props.credentials.auth ?
                    <div className="toolbar-item"> 
                    <NavDropdown
                        show={isClicked2 || isHovered2}
                        onMouseEnter={() => setIsHovered2(true)}
                        onMouseLeave={() => setIsHovered2(false)}
                        onToggle={() => setIsClicked2(!isClicked2)}
                        title={props.credentials.user.lastName + ", " + props.credentials.user.firstName} 
                        id="basic-nav-dropdown"
                        >
                        <div onClick={protectedClick} className="dropdown-items">
                        <div className="dropdown-items-sign"><Link to="/profile"><div className="dropdown-items-sign-icon">{profile}</div> <div className="dropdown-items-sign-text">Profile</div></Link></div>
                        <div className="dropdown-items-sign"><Link to="/settings"><div className="dropdown-items-sign-icon">{settings}</div> <div className="dropdown-items-sign-text">Settings</div></Link></div>
                        <NavDropdown.Divider />
                        <NavDropdown.Item
                            ><div onClick={onSubmit}>{signout} Log Out</div></NavDropdown.Item>
                        </div>
                    </NavDropdown> </div> : 
                    <div className="toolbar-item">
                        <Link to="/login/signin">Sign In</Link>
                    </div>
                }

            </div>
        </div>
    )

};

const mapStateToProps = state => ({
    credentials: state.credentials
})

const mapDispatchToProps = dispatch => {
    return {
        SignOut: () => dispatch(SignOut()),
        Auth: () => dispatch(Auth()),
        ToggleContact: (newState) => 
        dispatch(ToggleContact(newState))
    }
  }
  
export default connect(mapStateToProps, mapDispatchToProps)(Toolbar);
import React from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import UploadImage from './uploadimage'
import ProfileForm from './profileform'

import './login.css';

const Profile = props =>  {

  return  (
    <div className="profile">
    <Container fluid>
      <Row>
        <Col md="4"><UploadImage /></Col>
        <Col> <ProfileForm /> </Col>
      </Row>
    </Container>
    </div>
      
    ) 
}

const mapStateToProps = state => ({
  server: state.server,
  credentials: state.credentials
})

export default connect(mapStateToProps)(Profile);
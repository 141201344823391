import React, { useEffect, useState } from 'react';
import { Container, Row } from 'react-bootstrap';
import { URL } from '../../action/types';
import Loader from '../Loader/loader'

import './login.css';

const VerifyEmail = props =>  {
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [message, setMessage] = useState('');

    useEffect(() => {

        fetch(`${URL}/api/login/verify-email`, {
        method: 'POST',
        headers: {
          'auth-token': props.match.params.token,
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        }
      }).then(response => response.json()
        ).then(data => {

        setIsLoading(false)
        setIsError(data.error)
        setMessage(data.message)

        }
        ).catch(error => {
          console.log('error', error)
          setIsLoading(false)
          setIsError(true)
          setMessage("Server connection error")

      })

    }, [props.match.params.token]);


  return  (isLoading ? <Loader /> :
            isError ?

    <Container fluid>
        <Row>
            <div>
                <h1> Something went wrong! We couldn't verify your email :( </h1><br></br>
                <h4>Error Message: {message}</h4>
            </div>
          </Row>
        </Container> :

    <Container fluid>
      <Row>
        <div>
            <h1> Success:  {message} </h1>
        </div>
      </Row>
    </Container>
      
    ) 
}

export default VerifyEmail;
import React, { useLayoutEffect } from 'react';
import Chart from 'chart.js';
import './dashboard.css';

const PieChart = props =>  {
    const reducer = (accumulator, currentValue) => accumulator + currentValue;
    Chart.pluginService.register({
        beforeDraw: function (chart) {
          if (chart.config.options.elements.center) {
            //Get ctx from string
            var ctx = chart.chart.ctx;
      
            //Get options from the center object in options
            var centerConfig = chart.config.options.elements.center;
            var fontStyle = centerConfig.fontStyle || 'Arial';
            var txt = centerConfig.text;
            var color = centerConfig.color || '#000';
            var sidePadding = centerConfig.sidePadding || 20;
            var sidePaddingCalculated = (sidePadding/100) * (chart.innerRadius * 2)
            //Start with a base font of 30px
            ctx.font = "40px " + fontStyle;
      
            //Get the width of the string and also the width of the element minus 10 to give it 5px side padding
            var stringWidth = ctx.measureText(txt).width;
            var elementWidth = (chart.innerRadius * 2) - sidePaddingCalculated;
      
            // Find out how much the font can grow in width.
            var widthRatio = elementWidth / stringWidth;
            var newFontSize = Math.floor(30 * widthRatio);
            var elementHeight = (chart.innerRadius * 2);
      
            // Pick a new font size so it will not be larger than the height of label.
            var fontSizeToUse = Math.min(newFontSize, elementHeight);
      
            //Set font settings to draw it correctly.
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            var centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
            var centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);
            ctx.font = fontSizeToUse+"px " + fontStyle;
            ctx.fillStyle = color;
      
            //Draw text in center
            ctx.fillText(txt, centerX, centerY);
          }
        }
    });
  
  useLayoutEffect(() => {
    var nfObject = new Intl.NumberFormat('en-US'); 
    var ctx = document.getElementById(props.id).getContext('2d');
    var myChart = new Chart(ctx, {
      type: "doughnut",
      data: {
          labels: props.labels,
          datasets: [{
              label: 'Website Visits',
              data: props.data,
              backgroundColor: [
                  'rgba(255, 99, 132, 0.2)',
                  'rgba(54, 162, 235, 0.2)',
                  'rgba(255, 206, 86, 0.2)',
                  'rgba(75, 192, 192, 0.2)',
                  'rgba(153, 102, 255, 0.2)',
                  'rgba(255, 159, 64, 0.2)'
              ],
              borderColor: [
                  'rgba(255, 99, 132, 1)',
                  'rgba(54, 162, 235, 1)',
                  'rgba(255, 206, 86, 1)',
                  'rgba(75, 192, 192, 1)',
                  'rgba(153, 102, 255, 1)',
                  'rgba(255, 159, 64, 1)'
              ],
              borderWidth: 1
          }]
      },
      options: {
        title: {
            display: true,
            text: props.title
        },
        legend: {
            display: true,
            labels: {
                fontColor: '#666',
                boxWidth: 10
            }
        },
        elements: {
            center: {
            text: nfObject.format(props.data.reduce(reducer)),
            color: 'gray', //Default black
            fontStyle: 'Arial', //Default Arial
            sidePadding: 15 //Default 20 (as a percentage)
          }
        }
    }
  });

  }, [])

  return  (

      <div className="chart-container">
          <canvas id={props.id}></canvas>
      </div>
      
    ) 
}

export default PieChart;
import React from 'react';

import './logo.css';

class Logo extends React.Component {

  render() {
    return(
    <div className="logo" style={{width: '100%', margin: 'auto'}}>
        <svg xmlns="http://www.w3.org/2000/svg" width="127.5" height="290.278" viewBox="0 0 127.5 290.278">
            <ellipse className="bubble1"  cx="50.19" cy="-30.959" rx="5" ry="5" transform="translate(417.987 333.639)" fill="#FFA500" opacity="1"/>
            <ellipse className="bubble2"  cx="50.19" cy="-30.959" rx="2" ry="2" transform="translate(417.987 333.639)" fill="#FFA500" opacity="1"/>
            <ellipse className="bubble3"  cx="50.19" cy="-30.959" rx="1" ry="1" transform="translate(417.987 333.639)" fill="#FFA500" opacity="1"/>
            <ellipse className="bubble4"  cx="50.19" cy="-30.959" rx="7" ry="7" transform="translate(417.987 333.639)" fill="#FFA500" opacity="1"/>
            <ellipse className="bubble5"  cx="50.19" cy="-30.959" rx="1" ry="1" transform="translate(417.987 333.639)" fill="#FFA500" opacity="1"/>
            <ellipse className="bubble6"  cx="50.19" cy="-30.959" rx="5" ry="5" transform="translate(417.987 333.639)" fill="#FFA500" opacity="1"/>
            <ellipse className="bubble7"  cx="50.19" cy="-30.959" rx="3" ry="3" transform="translate(417.987 333.639)" fill="#FFA500" opacity="1"/>
            <ellipse className="bubble8"  cx="50.19" cy="-30.959" rx="4" ry="4" transform="translate(417.987 333.639)" fill="#FFA500" opacity="1"/>
            <g id="flask-group" transform="translate(-417.987 -68.181)">
                <ellipse className="big-flask-shadow2" cx="63.75" cy="12.41" rx="63.75" ry="12.41" transform="translate(417.987 333.639)" fill="#a2a2c0" opacity="0.1"/>
                <path className="big-flask-water1" d="M465.026,600.857s-3.4,49.6,41.242,50.493,44.372-49.906,44.372-49.906-16.489,18.82-55.464,3.285C495.176,604.741,474.981,599.063,465.026,600.857Z" transform="translate(-26.19 -329.361)" fill="#FFA500"/>
                <path className="big-flask-water2" d="M514,583.926s36.712-.336,55.067-5.135,28.844-.515,27.859,2.4-6.225,8.558-27.2,9.251S514,583.926,514,583.926Z" transform="translate(-75.19 -306.485)" fill="#FFA500"/>
                <path className="big-flask-shadow1" d="M486.855,535.641c-.769,6.152-.615,13.853,4.845,16.725-4.58-2.407,14.193-29.705,11.529-35.28C497.532,505.19,487.555,529.925,486.855,535.641Z" transform="translate(-56.19 -255.366)" fill="#a2a2c0" opacity="0.2"/>
                <path className="big-flask" d="M506.727,266.55V215.407h7.717V200.14h-55.3v15.267h7.717V266.55c-21.82,7.478-36.249,26.347-36.254,47.409,0,28,25.159,50.7,56.191,50.7s56.189-22.7,56.189-50.7c0-21.052-14.428-39.925-36.25-47.4Z" transform="translate(-6.19 -30.959)" fill="#dedee8" opacity="0.5"/>
            </g>
        </svg>

    </div>

    )
  }
}

export default Logo;
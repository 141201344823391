import { combineReducers } from'redux';
import authReducer from './authreducer'
import adminReducer from './adminreducer'
import serverReducer from './serverreducer'
import contactReducer from './contactreducer'

const rootReducer = combineReducers(
    
    {
    credentials: authReducer,
    server: serverReducer,
    admin: adminReducer,
    contact: contactReducer,
    }
)

export default rootReducer;
import React, { useLayoutEffect } from 'react';
import Chart from 'chart.js';
import './dashboard.css';

const BarChart = props =>  {
  
  useLayoutEffect(() => {
    var ctx = document.getElementById(props.id).getContext('2d');
    var myChart = new Chart(ctx, {
      type: props.isVertical ? "bar" : "horizontalBar",
      data: {
          labels: props.labels,
          datasets: [{
              label: 'Website Visits',
              data: props.data,
              backgroundColor: [
                  'rgba(255, 99, 132, 0.2)',
                  'rgba(54, 162, 235, 0.2)',
                  'rgba(255, 206, 86, 0.2)',
                  'rgba(75, 192, 192, 0.2)',
                  'rgba(153, 102, 255, 0.2)',
                  'rgba(255, 159, 64, 0.2)'
              ],
              borderColor: [
                  'rgba(255, 99, 132, 1)',
                  'rgba(54, 162, 235, 1)',
                  'rgba(255, 206, 86, 1)',
                  'rgba(75, 192, 192, 1)',
                  'rgba(153, 102, 255, 1)',
                  'rgba(255, 159, 64, 1)'
              ],
              borderWidth: 1
          }]
      },
      options: {
        scales: {
            xAxes: [{
                type: props.xType,
                distribution: 'series',
                offset: true,
                time: {
                    unit: 'month'
                }
            }],
            yAxes: [{
                ticks: {
                    beginAtZero: true
                }
            }]
        },
        title: {
            display: true,
            text: props.title
        },
        legend: {
            display: false,
            labels: {
                fontColor: '#666',
                boxWidth: 0
            }
        }
    }
  });

  }, [])

  return  (

      <div className="chart-container">
          <canvas id={props.id}></canvas>
      </div>
      
    ) 
}

export default BarChart;
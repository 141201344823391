import React, { useState } from 'react';
import { Form, Col } from 'react-bootstrap';
import './login.css';
import UpdateButton from './updatebutton'
import { connect } from 'react-redux';

const ProfileForm = (props) => {
    const [validated, setValidated] = useState(false);
    const [company, setCompany] = useState(props.credentials.user.company);
    const [firstName, setFirstName] = useState(props.credentials.user.firstName);
    const [lastName, setLastName] = useState(props.credentials.user.lastName);
    const [title, setTitle] = useState(props.credentials.user.title);
    const [phone, setPhone] = useState(props.credentials.user.phone);
    const [newEmail, setNewEmail] = useState(props.credentials.user.email);
    const [buttonName, setButtonName] = useState('Update');
    const [isEmailDisabled, setIsEmailDisabled] = useState(true);

    function handleSubmit(event) {
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      }
      

      
      setValidated(true);

      
    };


    function handleChange(event) {
      if (event.target.name === 'company') {
        setCompany(event.target.value)
      }
    
      if (event.target.name === 'title') {
        setTitle(event.target.value)
      }

      if (event.target.name === 'firstName') {
        setFirstName(event.target.value)
      }
    
      if (event.target.name === 'lastName') {
        setLastName(event.target.value)
      }

      if (event.target.name === 'phone') {
        setPhone(event.target.value)
      }

      if (event.target.name === 'email') {
        setNewEmail(event.target.value)
      }
    
    }

    function handleEdit() {
      let newState = !isEmailDisabled
      setIsEmailDisabled(newState)

      if (!newState) {
        setButtonName('Change Email')
      } else {
        setButtonName('Update')
      }
    
    }



  
    return (
      <div>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Form.Row>
          <Form.Group as={Col} md="6" controlId="validationCustom01">
              <Form.Label>Company</Form.Label>
              <Form.Control
                name="company"
                type="text"
                value={company}
                onChange={handleChange}
                placeholder="Company"
                disabled= {!isEmailDisabled}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="6" controlId="validationCustom01">
              <Form.Label>Title</Form.Label>
              <Form.Control
                name="title"
                type="text"
                value={title}
                onChange={handleChange}
                placeholder="Title"
                disabled= {!isEmailDisabled}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

          <Form.Group as={Col} md="6" controlId="validationCustom01">
            <Form.Label>First name</Form.Label>
            <Form.Control
              required
              name="firstName"
              type="text"
              value={firstName}
              onChange={handleChange}
              placeholder="First name"
              disabled= {!isEmailDisabled}
            />
            <Form.Control.Feedback type="invalid">Please choose First Name.</Form.Control.Feedback>
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} md="6" controlId="validationCustom02">
            <Form.Label>Last name</Form.Label>
            <Form.Control
              required
              name="lastName"
              type="text"
              value={lastName}
              onChange={handleChange}
              placeholder="Last name"
              disabled= {!isEmailDisabled}
            />
            <Form.Control.Feedback type="invalid">Please choose Last Name.</Form.Control.Feedback>
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} md="6" controlId="validationCustom01">
              <Form.Label>Phone</Form.Label>
              <Form.Control
                name="phone"
                type="text"
                value={phone}
                onChange={handleChange}
                placeholder="Phone"
                disabled= {!isEmailDisabled}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="6" controlId="validationCustom02">
            <Form.Label>E-mail <span onClick={handleEdit} className="edit"> (Edit)</span></Form.Label>
            <Form.Control
              required
              name="email"
              type="text"
              value={newEmail}
              onChange={handleChange}
              placeholder="E-mail"
              disabled= {isEmailDisabled}
            />
          </Form.Group>

          </Form.Row>
          </Form>

          <Form>
          <Form.Row>
          <Form.Group as={Col} md="6" controlId="button">
            <UpdateButton
            name={buttonName}
            company={company}
            title={title}
            firstName={firstName}
            lastName={lastName}
            phone={phone}
            newEmail={newEmail}
            emailDisabled={isEmailDisabled}
            clickEdit = {handleEdit}
            validated={validated}
            click={handleSubmit}> 
            </UpdateButton>
          </Form.Group>

          </Form.Row>
          </Form>
      </div>
    );
  }
  
  const mapStateToProps = state => ({
    credentials: state.credentials
  })
  
  export default connect(mapStateToProps)(ProfileForm);
import React, { useLayoutEffect, useState } from 'react';
import { URL } from '../../action/types';
import { Table } from 'react-bootstrap';
import SettingsForm from './settingsForm'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faSortUp, faSortDown, faDownload } from '@fortawesome/free-solid-svg-icons'
import Loader from '../Loader/loader';
import Pagination from '../Tables/pagination';
import { dateTime, shortDate, lookupName } from '../../Utilities/converters';
import { exportExcel } from '../../Utilities/exportExcel';

import './settings.css';

const SettingsTable = props =>  {
    const [buffer, setBuffer] = useState(true);
    const [collection, setCollection] = useState([]);
    const [fields, setFields] = useState([]);
    const [lookupFields, setLookupFields] = useState({});
    const [sortField, setSortField] = useState("createdDate");
    const [sortOrder, setSortOrder] = useState(-1);
    const [currentPage, setCurrentPage] = useState(0);
    const [numPages, setNumPages] = useState(0);
    const [success, setSuccess] = useState(false);
    
    //Form Variables
    const [isHiddenForm, setFormHidden] = useState(true);
    const [formId, setFormId] = useState("");
    const [isNew, setIsNew] = useState(false);
    
    useLayoutEffect(() => {
        setSuccess(false)
        setBuffer(true)
        setSortField("createdDate")
        setSortOrder(-1)
        setCurrentPage(0)

        let store = JSON.parse(localStorage.getItem('fabylab-login'))
        let token = null
        if ((store && store.login)) {
          token = store.token
      
        }
        
        fetch(`${URL}/api/crud/${props.collectionName}/all?currentPage=0&sortField=createdDate&sortOrder=-1`, {
            method: 'GET',
            headers: {
            'auth-token': token,
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            }
        })
        .then(response => response.json())
        .then(data => {
            setCollection(data.collection)
            setFields(data.schema)
            setLookupFields(data.lookups)
            setNumPages(data.numPages)
            setSuccess(data.success)
            setBuffer(false)
        })
        .catch(error => {
            console.log(error)
            setBuffer(false)
            setSuccess(false)
        })

        

    }, [props.collectionName])

    function refreshPage(page, sort, order) {

        if (order === undefined) {
            order = sortOrder
        }

        if (sort === undefined) {
            sort = sortField
        }

        if (page !== currentPage) {
            setCurrentPage(page)
        }

        let store = JSON.parse(localStorage.getItem('fabylab-login'))
        let token = null
        if ((store && store.login)) {
          token = store.token
      
        }
        
        fetch(`${URL}/api/crud/${props.collectionName}/all?currentPage=${page}&sortField=${sort}&sortOrder=${order}`, {
            method: 'GET',
            headers: {
            'auth-token': token,
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            }
        })
        .then(response => response.json())
        .then(data => {
            setCollection(data.collection)
            setFields(data.schema)
            setLookupFields(data.lookups)
            setNumPages(data.numPages)
            setSuccess(data.success)
            setBuffer(false)
        })
        .catch(error => {
            console.log(error)
            setBuffer(false)
            setSuccess(false)
        })

    }

    function handleClick(data, formState) {
        toggleForm(formState)
        setFormId(data)
    }

    function toggleForm(formState) {
        setFormHidden(!isHiddenForm)
        setIsNew(formState)
    }
    
    function sortIconToggle(field, element) {

        var sortDirection = element.getAttribute("sort")
        var sortupElements = document.getElementsByClassName("table-settings-header-sortup")
        var sortdownElements = document.getElementsByClassName("table-settings-header-sortdown")
        for (var i=0; i< sortupElements.length; i++) { 
            if (sortupElements[i].id === `sortup-${field}` && sortDirection === "down") {
                sortupElements[i].style.display = "inline"
                element.setAttribute("sort", "up")
            } else {
                sortupElements[i].style.display = "none"
            }

            if (sortdownElements[i].id === `sortdown-${field}` && sortDirection === "up") {
                sortdownElements[i].style.display = "inline"
                element.setAttribute("sort", "down")
            } else {
                sortdownElements[i].style.display = "none"
            }
    
        }

    }

    function customSort(field, element) {
        setSortField(field)
        var sortDirection = element.getAttribute("sort")
        sortIconToggle(field, element)

        if (sortDirection === "up") {
            setSortOrder(1)
            refreshPage(currentPage, field, 1)
        } else {
            setSortOrder(-1)
            refreshPage(currentPage, field, -1)
        }

    }

    function updateRow(updatedCollection, type) {

        if (type === "PUT") {
            collection.forEach((item, index) => {
                if (updatedCollection._id ===  item._id) {
                    collection[index] = updatedCollection
                }
            })
        } else {
            collection.unshift(updatedCollection)
        }

    }



  return  (buffer ? <Loader/> :
    success ? 
    <div className="table-settings-container">

        <div className="table-settings-buttons-container">
            {/* {NEW BUTTON} */}
            <div className="table-settings-buttons" onClick={() => handleClick("", true)}> 
                <FontAwesomeIcon 
                icon={faPlusCircle}
                className="form-settings-button-new"
                size="2x"
                />
                <span 
                className="form-settings-button-new-label">New Item</span>
            </div>

            {/* {DOWNLOAD BUTTON} */}
            <div className="table-settings-buttons" 
            onClick={() => exportExcel("fabylab-table-" + props.collectionName, fields, collection, lookupFields )}> 
                <FontAwesomeIcon 
                icon={faDownload}
                className="form-settings-button-new"
                size="2x"
                />
                <span 
                className="form-settings-button-new-label">Export Excel</span>
            </div>
        </div>

        <Table className="table-settings" striped bordered hover variant="light">

        {/* {TABLE HEADERS} */}
        <thead>
        <tr>
            {fields.map((header, index) =>
            header.isTable ? 
            <th 
            key={header.apiName}
            id={header.apiName}
            sort={"up"}
            style={{minWidth:"150px"}}
            className="table-settings-header"
            onClick={() => customSort(header.apiName , document.getElementById(header.apiName))}
            >
            {header.displayName}

            <FontAwesomeIcon
            id={`sortup-${header.apiName}`}
            style={{display: "none"}} 
            icon={faSortUp}
            className="table-settings-header-sortup"
            size="1x"
            />

            <FontAwesomeIcon
            id={`sortdown-${header.apiName}`}
            style={{display: "none"}} 
            icon={faSortDown}
            className="table-settings-header-sortdown"
            size="1x"
            />
            </th> : null
            )}
        </tr>

        </thead>
        <tbody>
            {/* {TABLE ROWS/CONTENT} */}
            {collection.map((item, index) =>
                <tr key={`tr${index}`}>
                    {fields.map((header, index) =>
                    header.isTable ? 
                    <td key={`th${header.apiName}`}>
                        {header.fieldType === "lookup" ? lookupName(lookupFields, header.lookup, item[header.apiName]) :
                         header.fieldType === "date" ? shortDate(item[header.apiName]) :
                         header.fieldType === "dateTime" ? dateTime(item[header.apiName]) :
                         header.fieldType === "link" ? <a href={item[header.apiName]} target="_blank"  rel="noreferrer">link</a>:
                         header.fieldType === "boolean" ? item[header.apiName] ? "Yes" : "No" :
                         header.isTitle ? 
                         <a 
                         className="table-settings-table-title"
                         onClick={() => handleClick(item._id, false)}
                         > {item[header.apiName]} </a>: item[header.apiName]}
                    </td> : null
                    )}
                </tr>
            )}
        </tbody>
        </Table>

        {/* {PAGINATION} */}
        <Pagination
        numPages={numPages}
        refreshPage={refreshPage}
        />

        {/* {CREATE/UPDATE FORM} */}
        <SettingsForm
        hidden={isHiddenForm}
        closeForm={toggleForm}
        formId={formId} 
        fields={fields}
        collectionName={props.collectionName}
        success={success}
        lookups={lookupFields}
        isNewForm={isNew}
        updateRow={updateRow}/>
    </div> : null

    ) 
}

  
export default SettingsTable;
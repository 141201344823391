import React from 'react';
import Projects from '../Projects/projects';
import './landingPage.css';

export default class Landing extends React.Component {

    render() {
      
        return  (
            <div className="landing-container">
              <div className="landing-banner-container">
                <div className="landing-banner-shade"/> 

                <div className="landing-banner-text">
                  <div className="landing-banner-text-title">
                    
                    <h1><span>Experiment the <span style={{color: "orange"}}>Future</span></span></h1>
                  </div>
                  <div className="landing-banner-text-description">
                    <h1><span>Experiential learning and Innovation with Advanced Technology</span></h1>
                    
                    </div>
                </div>
            </div>
              <div className="landing-about-container">
                We give people the power to experiment the future by creating, partnering or investing with us.
              </div>
              <div className="landing-options-container">
                <div className="landing-options-card-container"> 
                  <div className="landing-options-card-header"> 
                  <span>For Individuals</span>
                  </div>
                  <div className="landing-options-card-icon"> 
                    <div className="landing-options-card-icon-network"/> 
                  </div>  
                  <div className="landing-options-card-description"> 
                  <span>Join our network of innitiatives and collaborate with us to explore the future together.</span>
                  </div>
                </div>

                <div className="landing-options-card-container">
                  <div className="landing-options-card-header"> 
                    <span>For Companies</span>
                  </div>
                  <div className="landing-options-card-icon"> 
                    <div className="landing-options-card-icon-partner"/> 
                  </div> 
                  <div className="landing-options-card-description"> 
                    <span>Embark on our space ship and bring your business to the future.</span>
                  </div>
                </div>

                <div className="landing-options-card-container"> 
                  <div className="landing-options-card-header"> 
                    <span>For Investors</span>
                  </div>
                  <div className="landing-options-card-icon"> 
                    <div className="landing-options-card-icon-investor"/> 
                  </div>  
                  <div className="landing-options-card-description"> 
                    <span>Explore our seed funding opportunities.</span>
                  </div>
                </div>

              </div>
              <Projects
              defaultFilter={["60163279e75d838e02486557",
                              "60163383e75d838e02486558", 
                              "6016338ee75d838e02486559", 
                              "60163393e75d838e0248655a"]}/>
            </div>
          )

    }

}




import React from 'react';
import { shortDate } from '../../Utilities/converters';
import './articleCard.css';


const ArticleCard = props => {

    const imageStyle = {
        backgroundImage: `url(${props.article.image})`,
    };

    function displayName(list, id) {
        var display = ""
        list.map((item, index) => 
            item._id === id ? display = item.name : null    
        )
        return display;
    }

    return (
        <div id={props.id} className="articleCard" onClick={() => props.click(`articles/${props.article.url}`)}>
            <div id={`${props.id}-image`} className={`articleCard-image`} style={imageStyle}/>
            <div className={`articleCard-shade`} />

            <div className={`articleCard-text-container`}>
                <div className={`articleCard-text-category`}>
                    <span>{displayName(props.categories, props.article.categoryID)}</span>
                </div>
                <div className={`articleCard-text-header`}>
                    <span>{props.article.name}</span>
                </div>

                <div className={`articleCard-text-description`}>
                    <span>{props.article.description}</span>
                </div>

                <div className={`articleCard-text-signature`}>
                    <span>Author: Fabio Chen</span> <span>Published: {shortDate(props.article.createdDate)}</span>
                </div>
            </div>


        </div>
    )

};

export default ArticleCard;

import React from 'react';
import Projects from '../Projects/projects';
import './ai.css';

const AI = props => {

    return (
        <div className="ai-container">
          <div className="ai-banner-container">
            <div className="ai-banner-image-container"> 
              <div className="ai-banner-text"> 
                <h1><span>Artificial <span style={{color: "orange"}}>Intelligence</span></span></h1>
              </div> 
              <div className="ai-banner-image"/> 
              
            </div> 
            <div className="ai-banner-content"> 
              <iframe
              className="ai-banner-content-video"

              src="https://www.youtube.com/embed/khjY5LWF3tg?start=1&controls=1&modestbranding=1" 
              frameBorder="0" 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
              allowFullScreen>
              </iframe>

            </div>


          </div>
        <Projects
        defaultFilter={["60163279e75d838e02486557"]}/>
      </div>
    )

};

export default AI;
import React from 'react';
import './categoriesFilter.css';

const Projects = props => {

  function handleFilter() {
    var newFilter = [];
    props.categories.map((item, index) => 
      document.getElementById(`category${item._id}`).checked ? newFilter.push(item._id) : null
    )
    props.changeFilter(newFilter)
  }

  function countProjects(category) {
    var occurencies = 0;
    props.list.map((item, index) =>
    item.categoryID === category ? occurencies++ : null
    )
    return occurencies;
  }

    return (
    <div className="categories-filter-container"> 
      <span className="categories-filter-title">Filter:</span>
      <div className="projects-filter-content">
        {props.categories.map((item, index) =>
        <form key={index} className="categories-filter-checkbox-container">
          <input type="checkbox" 
          id={`category${item._id}`}
          className="categories-filter-checkbox"
          onClick={handleFilter}
          defaultChecked ={props.filter.includes(item._id)}/>
          <span className="filter-label"> {item.name} </span>
          <span className="filter-label-number">{`(${item.count})`}</span>
        </form>)}
      </div>
    </div>
    )

};

export default Projects;

import React from 'react';
import './footer.css';


const Footer = props => {

    return (
    <div className="footer"> 
        <div className="footer-text">
            2020, All rights reserved. FabyLab Technologies
            
        </div>
    </div>
    )

};

export default Footer;

import { AUTH, SERVER, URL } from './types';

export const SignIn = (email, password) => dispatch => {
    dispatch({
      type: SERVER,
      payload: {isLoading: true, error: false, success:false, message: ''}
    })

    fetch(`${URL}/api/login/signin`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify({
          email: email,
          password: password
        })
      }).then(response => response.json()
        ).then(data => {
          if (data.auth) {
            localStorage.setItem('fabylab-login', JSON.stringify({
              login: true,
              token: data.token,
            }))


            }
            dispatch({
              type: SERVER,
              payload: {isLoading: false, error: data.error, success: data.success, message: data.message}
            })

            dispatch({
                type: AUTH,
                payload: data
            })
        }
        ).catch(error => {
          console.log('error', error)

          dispatch({
            type: SERVER,
            payload: {isLoading: false, error: true, message: "Server connection error"}
        })

        dispatch({
            type: AUTH,
            payload: {auth: false, message: "Sorry, we are facing connection issues."}
        })
    })
}

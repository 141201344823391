export const dateTime = (longDate) => { 
    var date = new Date(longDate)

    var hour = date.getHours() < 10 ? 
    "0" + (date.getHours() ): (date.getHours() )

    var minute = date.getMinutes() < 10 ? 
    "0" + (date.getMinutes() ): (date.getMinutes() )

    var second = date.getSeconds() < 10 ? 
    "0" + (date.getSeconds() ): (date.getSeconds() )

    return (date.getMonth() + 1) + 
    "/" +  (date.getDate()) +
    "/" +  date.getFullYear() + 
    " " + hour + 
    ":" + minute + 
    ":" + second;
}


export const shortDate = (longDate) => { 
    var date = new Date(longDate)

    return (date.getMonth() + 1) + 
    "/" +  (date.getDate()) +
    "/" +  date.getFullYear();
}


export const lookupName = (lookupFields, collection, id) => { 
    var display = ""
    Object.keys(lookupFields).forEach(key => {
        if (collection === key) {
            lookupFields[key].forEach(item => item._id === id ? display = item.name : null)
        }
    })
    
    return display;
}
import React, { useState } from 'react';
import './login.css';
import { useLocation, Redirect, useParams } from "react-router-dom";
import Signin from './signin'
import ForgotPassword from './forgotpassword'
import { connect } from 'react-redux';
import { Auth } from '../../action/authactions'

const Login = (props) => {

    let location = useLocation();
    let { url } = useParams();
    const [sourceLocation] = useState(location.source);

    return  ( !props.credentials.auth ?

        <div className="login-container">
          
          <div className="login-form-container">

            {url === "forgotpassword" ? <ForgotPassword/>  : <Signin/>}
            
            
          </div>
          <div className="login-background-container"></div>

        </div> : <Redirect
                    to={{
                      pathname: sourceLocation,
                    }}
                  />
    
    )

}

const mapStateToProps = state => ({
  credentials: state.credentials,
  server: state.server
})

export default connect(mapStateToProps, {Auth} )(Login);
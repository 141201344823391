import React, { useLayoutEffect } from 'react';

import './dashboard.css';

const TableChart = props =>  {
    const reducer = (accumulator, currentValue) => accumulator + currentValue;

  
  useLayoutEffect(() => {

  }, [])

  return  (

      <div className="chart-container">
        <div className="chart-title"> {props.title} </div>
        <table className="table-chart">
            {/* {TABLE HEADERS} */}
            <thead>
            <tr>
                {props.labels.map((label, index) =>
                <th 
                key={index}
                id={label}
                sort={"up"}
                style={{minWidth:"150px"}}
                >
                {label}
                </th>
                )}
            </tr>
            </thead>

            {/* {TABLE ROWS/CONTENT} */}
            <tbody>
            {props.data[0].map((column, index) =>
                <tr key={`tr${index}`}>
                    {props.data.map((row) =>
                    <td key={`th${row}`}>
                        {row[index]}
                    </td>
                    )}
                </tr>
            )}
            </tbody>
        </table>
      </div>
      
    ) 
}

export default TableChart;
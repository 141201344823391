import React from 'react';
import Projects from '../Projects/projects';
import './digitalStrategy.css';

const DigitalStrategy = props => {

    return (
      <div className="digitalStrategy-container">
      <div className="digitalStrategy-banner-container">
        <div className="digitalStrategy-banner-text">
          <h1><span style={{color: "black"}}>Digital <span style={{color: "orange"}}>Strategy</span></span></h1>
          <span style={{color: "gray", fontSize: "1.5rem"}}>A competitive digital strategy consists in a good 
          mix of people, process and technology. Leaving any of these components out of the equation can compromise its execution.</span>
        </div>

        <div className="digitalStrategy-banner-image"/> 
      
      </div>
        <Projects
        defaultFilter={["6016338ee75d838e02486559"]}/>
      </div>
    )

};

export default DigitalStrategy;